import React from "react";
import { createRecord, getRecordsOptions, readRecord } from "../../utils";
import AutocompleteDynamicListSelectorField from "../../widgets/AutocompleteDynamicListSelectorField";
import { whoami } from "../../../../auto/js/users/UserInfo";
import { rest, t } from "../../../../auto/js/services";
import { getDjServicesUrl } from "../../../../auto/js/metadata";

export const customRegistreOppositionForm2Dto = (formData, dto) => {
    if (formData.partie !== null && formData.partie) {
		let partie = Object.keys(formData.partie).join(',');
		dto.partie = partie;
	}
}

export const newRegistreOpposionForm2FormDto = (formData, dto) => {
    dto.judgmentNumber = formData.id;
    dto.id = null;  
    dto.judgmentDate = formData.date;
    dto.devantNous = whoami().firstname + ' ' + whoami().lastname
}

export const partieComponentHook = (name, disabled) => {
    return <AutocompleteDynamicListSelectorField time={1000} readEntry={readRecord} createEntry={(data, callback, onCancel) => createRecord(data, callback, onCancel)} searchRequest={async (filter) => getRecordsOptions(filter)} name={name} readOnly={disabled} label={t`Partie`} />
}

export const customRegistreOppositionDto2Form = async (dto, form) => {
    if (dto.partie == null || dto.partie === "")
        dto.partie = {}
    else {
        let values = {};
        let components = dto.partie.split(",");
        // Use Promise.all to wait for all rest.read calls
        await Promise.all(
            components.map(async (element) => {
                const r = await rest.read('records', element, getDjServicesUrl());
                values[parseInt(element)] = r.firstname + ' ' + r.othernames + ' ' + r.lastname;
            })
        );
        form.partie = values;
    }
    return form;
}