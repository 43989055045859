import React from "react";

import { getDjServicesUrl, getServiceUri } from "../../auto/js/metadata";
import { rest, t } from "../../auto/js/services";
import { OPEN_CREATE_OR_SEARCH, OPEN_SIDE_PANEL, PRINT_EVENT } from "../../auto/js/events/Gui";
import TemplateComponent from "react-mustache-template-component";
import { loadFormData, recordsFields } from "../../auto/js/forms/records/NewRecordsForm";
import { createFormComponent } from "../../auto/js/widgets";

const gender = {1:"MALE", 2:"FEMALE"};
const maritalStatus = {1:"SINGLE", 2:"MARRIED", 3:"DIVORCED", 4:"WIDOWED"};

export const loadPersonData = async (id) => {
    return  rest.read("records", id).then(response => {
        if (response.status)
            return undefined;
        else {
            response['image'] = { x: 0.5, y: 0.5, scale: 1, rotate: 0, url: '/public/avatar.png', isEmpty: true };
            return response;
        }
    })
}

export const filterPersonData = (data) => {

    let faceUrl = null;
    if (data.faceId != null) {
        faceUrl = getServiceUri() + "face/image/" + data.faceId;
    }
    data['image'] = { x: 0.5, y: 0.5, scale: 1, rotate: 0, url: (faceUrl != null) ? faceUrl : '/public/avatar.png', isEmpty: true };                  
    if (data.maritalStatus)
        data.maritalStatus = maritalStatus[data.maritalStatus];
    if (data.gender)
        data.gender = gender[data.gender];
    return data;
        
}

export const loadSubstitut = async (id) => {
    return rest.read("user-entity", id).then(response => {
        if (response.status)
            return undefined;
        else {
            return response
        }
    })
}

export const loadCaseData = async (id) => {
    let data = {id: id};
    let plaignants = [];
    let personFilter = {and: true};
    personFilter['plaignants'] = {folderId: id};
    await rest.search('plaignants-records', personFilter, getDjServicesUrl()).then(p => {
        if (p.length) {
            p.forEach(element => {
                let plaignant = element.firstname;
                if (element.lastname != null) 
                    plaignant = plaignant.concat(' ', element.lastname)
                plaignants.push(plaignant);
            });
        }
        data.plaignants = plaignants;
    })

    let victimes = [];
    let vFilter = {and: true};
    vFilter['victimes'] = {folderId: id};
    await rest.search('victimes-records', vFilter, getDjServicesUrl()).then(p => {
        if (p.length) {
            p.forEach(element => {
                let victime = element.firstname;
                if (element.lastname != null)
                    victime = victime.concat(' ', element.lastname)
                victimes.push(victime);
            });
        }
        data.victimes = victimes;
    })

    let misCauses = [];
    let mFilter = {and: true};
    mFilter['misCause'] = {folderId: id};
    await rest.search('mis-causes-records', mFilter, getDjServicesUrl()).then(p => {
        if (p.length) {
            let first = true;
            p.forEach(element => {
                let misCause = element.firstname;
                if (element.lastname != null) 
                    misCause = misCause.concat(' ', element.lastname)
                misCauses.push(misCause)
            });
        }
        data.misCauses = misCauses;
    })
    await rest.read('folder', id, getDjServicesUrl()).then(f => {
        data.rp = f.rpId
        data.legacyId = f.legacyId
    })
    return data;
}

export const getRecordsOptions = async (filter) => {
    let recordFilter = {and: false, limit: 10, fuzziness: "MIN"};
    if (filter) {
        recordFilter['records-document'] = {};
        recordFilter['records-document'].lastname = filter;
        recordFilter['records-document'].othernames = filter;
        recordFilter['records-document'].firstname = filter;
        return rest.searchDocument('records', recordFilter, getDjServicesUrl()).then(response => {
            const result = response.reduce((acc, item) => {
                let firstName = (item.firstname !== null)?item.firstname + ' ':'';
                let othernames = (item.othernames !== null)?item.othernames + ' ' :'';
                let lastname = (item.lastname !== null)?item.lastname:'';
                acc[item.id] = firstName + othernames + lastname;
                return acc;
            }, {});
            return result
        })
    }
    else
        return {}
}


const addRecordCallback = (id, addEntry) => {
    rest.read('records', id, getDjServicesUrl()).then(response => {
        response.label =   `${(response.firstname)?response.firstname:''} ${(response.othernames)?response.othernames:''} ${(response.lastname)?response.lastname:''}`;
        addEntry(response);
    })
}


export const createRecord = (data, addEntry, onCancel) => {
    const c = (id) => {addRecordCallback(id, addEntry)};
    let names = data.split(' ');
    let lastname ="";
    let othernames="";
    let firstname = names[0];
    if (names.length > 1)
        lastname = names[names.length - 1];
    if (names.length > 2)
        othernames = names.slice(1, -1).join(' ');
    let dto = {firstname: firstname, lastname: lastname, othernames: othernames}
    OPEN_CREATE_OR_SEARCH.publish({callback: c, data: dto, cancel: onCancel})
}

export const readRecord = (id) => {
    let RecordsForm = createFormComponent(recordsFields);
    OPEN_SIDE_PANEL.publish(
        { body: 
            <RecordsForm loadData={async () => loadFormData(id)} id={id} buttons={() => null} />    
        , title: t`Person` }
    )
}


export const Section = ({name}) => {
	return (
		<>
		<div class="col-md-12 sectiontitle">
			<h2 style={{ display: "inline" }}>{t(name)}</h2>
		</div>
		</>
	)
}

export const printTemplate = (data) => () => {
	PRINT_EVENT.publish({
		content: <TemplateComponent template={data.template} data={data.templateData} />
	})
}