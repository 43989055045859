import React from "react";
import {v4 as uuidv4} from 'uuid';
import _ from 'lodash';
import { CAN_I_SWITCH } from "../../../../auto/js/events/Gui";
import { pojoMetadata, getServiceUri } from "../../../../auto/js/metadata";
import { rest, t } from "../../../../auto/js/services";
import { showNotification, swapObject } from "../../../../auto/js/utils";
import { AlertDialog } from "../../../../auto/js/widgets/Dialogs";
import { createFormComponent } from "../../../../auto/js/widgets/FormComponent";
import { customRegistreOppositionForm2Dto, customRegistreOppositionDto2Form, newRegistreOpposionForm2FormDto } from "../../../../main/js/forms/registreOpposition/RegistreOppositionFormCommon.jsx";
import * as Yup from 'yup';
import {
	partieComponentHook
} from "../../../../main/js/forms/registreOpposition/RegistreOppositionFormCommon.jsx";
import { CaseComponent } from "../../../../main/js/widgets/CaseComponent";
import { loadCaseData } from "../../../../main/js/utils";


export const registreOppositionFields = [
    {
        name: "id",
        label: "Numéro électronique dans le registre d'opposition",
        type: "number",
        x: "1",
        y: "1",
        layout: "col-md-6",
        disabled: true,
    },
    {
        name: "folderId",
        label: "Case ID",
        type: "custom",
        x: 1,
        y: 2,
        layout: "col-md-12",
        disabled: true,
		component: (name, disabled) => <CaseComponent name={name} disabled={disabled} loadData={loadCaseData} />
    },
    {
        name: "judgmentNumber",
        label: "judgmentNumber",
        type: "number",
        x: "1",
        y: "2",
        layout: "col-md-6",
    },
    {
        name: "judgmentDate",
        label: "judgmentDate",
        type: "date",
        x: "1",
        y: "3",
        layout: "col-md-6",
    },
    {
        name: "partie",
        label: "Partie",
        type: "custom",
        x: "1",
        y: "3",
        layout: "col-md-12",
        component: (name, disabled) => partieComponentHook(name, disabled)
    },
    {
        name: "interprete",
        label: "interprete",
        type: "text",
        x: "1",
        y: "5",
        layout: "col-md-6",
    },
    {
        name: "devantNous",
        label: "devantNous",
        type: "text",
        x: "1",
        y: "6",
        layout: "col-md-6",
    },
    {
        name: "comparu",
        label: "comparu",
        type: "text",
        x: "2",
        y: "6",
        layout: "col-md-6",
    },
    {
        name: "qui",
        label: "qui",
        type: "text",
        x: "1",
        y: "7",
        layout: "col-md-12",
    },
    {
        name: "attachments",
        label: "attachments",
        type: "file",
        x: "1",
        y: "8",
        layout: "col-md-12",
        uploadUrl: (id) => getRegistreOppositionAttachmentsUploadUrl(id),
        previewUrl: (id) => getRegistreOppositionAttachmentsPreviewUrl(id),
        loadData: async (id) => await loadRegistreOppositionAttachmentsData(id),
        handleDelete:(id) => handleRegistreOppositionAttachmentsDelete(id),
        handleClick: (id) => handleRegistreOppositionAttachmentsClick(id),
        display: (data) => {return data.attachments},
        updateFileData: (data) => updateRegistreOppositionAttachmentsData(data),
    }
]

const getRegistreOppositionAttachmentsUploadUrl = (id) => {
    return getServiceUri() + 'registre-opposition/attachments' + '/' + id;
};
const handleRegistreOppositionAttachmentsClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'registre-opposition/attachments' + '/' + id + '/' + token;
};
const getRegistreOppositionAttachmentsPreviewUrl = (id) => {
	return getServiceUri() + 'registre-opposition/attachments' + '/preview/' + id + '/';
};
const updateRegistreOppositionAttachmentsData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getServiceUri() + 'registre-opposition/attachments' + '/' + data.id, "PUT", filter);
};
const handleRegistreOppositionAttachmentsDelete = (id) => {
	rest.delete('registre-opposition/attachments', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};
const loadRegistreOppositionAttachmentsData = async (id) => {
	let filter = {and: true};
	filter['registre-opposition-attachments'] = {};
	filter['registre-opposition-attachments']['registreOppositionId'] = id;
	return await rest.search('registre-opposition/attachments', filter)
}

export const form2dto = (formData, dto) => {
    if (formData.id != null) {
        dto.id = parseInt(formData.id)
    }
    if (formData.folderId != null) {
        dto.folderId = parseInt(formData.folderId)
    }
    if (formData.judgmentNumber != null) {
        dto.judgmentNumber = parseInt(formData.judgmentNumber)
    }
    if (formData.judgmentDate != null && typeof(formData.judgmentDate) != 'string') {
        let judgmentDateAsDate = new Date(formData.judgmentDate);
        dto.judgmentDate = judgmentDateAsDate.getFullYear() + "-" + ('0' + (judgmentDateAsDate.getMonth() + 1)).slice(-2) + "-" + ('0' + judgmentDateAsDate.getDate()).slice(-2);
    }
    customRegistreOppositionForm2Dto(formData, dto);
}

export const dto2form = async (dto) => {
    let form = dto;
    return customRegistreOppositionDto2Form(dto, form).then((form) => {
        return form;
    });
}

const getButtons = (id, onFinish) => {
    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    
                        <button style={{ minWidth: '5rem' }} className={'reject-button'} type="submit">
                            {t`Submit`}
                        </button>
                    
                </div>
            </div>
        </>
    )
}

class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
	    let RegistreOppositionForm = createFormComponent(registreOppositionFields);
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={() => this.handleDontSave(this.props.id)}
			/>
			<RegistreOppositionForm ref={this.myRef} key={this.props.key} loadData={this.props.loadData} onSubmit={this.props.onSubmit} id={this.props.id} buttons={() => getButtons(this.props.id, this.props.onFinish)} />
		</>
		)
	}
}

export const displayNewRegistreOppositionForm = (gui, formData) => {
	const readNewRegistreOppositionForm = (onFinish) => (id) => {
        let uuid = uuidv4();
        return {
            uuid, view: () => <FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>

        };
    }
    let data;
    if (!formData) {
        data = buildEmptyObject(registreOppositionFields);
        data.draft = true;
        save(data).then((response) => {
            gui.goTo(readNewRegistreOppositionForm(), response)
        });
    } else {
        data = _.clone(formData);
        data.draft = true;
        newRegistreOpposionForm2FormDto(formData, data);
        if (formData.image) {
            if (!formData.image.isEmpty) {
                let base64Image = formData.image.url;
                fetch(base64Image)
                .then(res => res.blob())
                .then(blob => {
                    let faceMimeType = "image/png";
                    data.face = blob;
                    data.faceMimeType = faceMimeType;
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        data.image.url = reader.result;
                        save(data).then((response) => {
                            gui.goTo(readNewRegistreOppositionForm(), response)
                        });
                    };
                    reader.readAsDataURL(blob);
                });
            } else {
                save(data).then((response) => {
                    gui.goTo(readNewRegistreOppositionForm(), response)
                });
            }
        } else {
            save(data).then((response) => {
                gui.goTo(readNewRegistreOppositionForm(), response)
            });
        }
    }
}

export const displayReadRegistreOppositionForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>
	};
}

const buildEmptyObject = (fields) => {
	const empty = {};
	for (let i = 0; i < fields.length; i++) {
		let field = fields[i];
		switch (field.type) {
			case ("text"):
				empty[field.name] = "";
				break;
			case ("number"):
				empty[field.name] = "";
				break;
			case ("checkbox"):
				empty[field.name] = false;
				break;
			case ("timestampz"):
				empty[field.name] = '';
				break;
			case ("date"):
				empty[field.name] = null;
				break;
			case ("select"): // dynamic lists, loaded from the backend
				empty[field.name] = '';
				break;
			case ("list"): // static lists, hardcoded
				empty[field.name] = '';
				break;
			case ("password"):
				empty[field.name] = '';
				break;
            case ("image"):
                empty[field.name] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: '/public/avatar.png', isEmpty: true};
                break;
		}
	}
	return empty;
}

const loadFormData = async (id) => {
	return await rest.read('registre-opposition', id).then(response => {
		return dto2form(response).then((form) => {
            return form;
        });
	})
}

const save = async (formData) => {
	let data = _.clone(formData);
	form2dto(formData, data);
	try {
        return rest.request(getServiceUri() + 'registre-opposition/new-document', 'POST', data).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
				return response;
		});
    } catch (err) {
        alert(err);
    }
}

const update = async (formData) => {
	let data = _.clone(formData);
	form2dto(formData, data)
	try {
        return rest.request(getServiceUri() + 'registre-opposition/edit-document', 'POST', data).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated registreOpposition`, "success");
		});
    } catch (err) {
        alert(err);
    }
}