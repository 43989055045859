import React from "react";
import {v4 as uuidv4} from 'uuid';
import _ from 'lodash';
import { CAN_I_SWITCH } from "../../../../auto/js/events/Gui";
import { pojoMetadata, getServiceUri, getDjServicesUrl } from "../../../../auto/js/metadata";
import { rest, t } from "../../../../auto/js/services";
import { showNotification, swapObject } from "../../../../auto/js/utils";
import { AlertDialog } from "../../../../auto/js/widgets/Dialogs";
import { createFormComponent } from "../../../../auto/js/widgets/FormComponent";
import { customMandatDepotForm2Dto, customMandatDepotDto2Form } from "../../../../main/js/forms/mandatDepot/MandatDepotFormCommon.jsx";
import * as Yup from 'yup';
import {
    birthPlaceComponentHook,
	citizenshipComponentHook,
	addressComponentHook
} from "../../../../main/js/forms/mandatDepot/MandatDepotFormCommon.jsx";
import { displayReadApprovedMandatDepotForm, printMandatDepot } from "./ApprovedMandatDepotForm";


export const mandatDepotFields = [
    {
        name: "id",
        label: "Numéro de série",
        type: "number",
        x: "1",
        y: "1",
        layout: "col-md-6",
    },
    {
        name: "firstname",
        label: "firstname",
        type: "text",
        x: "1",
        y: "2",
        layout: "col-md-6",
    },
    {
        name: "lastname",
        label: "lastname",
        type: "text",
        x: "2",
        y: "2",
        layout: "col-md-6",
    },
    {
        name: "birthdate",
        label: "birthdate",
        type: "date",
        x: "1",
        y: "3",
        layout: "col-md-6",
    },
    {
        name: "birthBox",
        label: "Birth Place",
        type: "box",
        x: "1",
        y: "4",
        layout: "col-md-12",
        components: [
            {
                name: "birthPlace",
                layout: "col-md-12",
                type: "custom",
                x: "1",
                y: "1",
                component: (name, disabled) => birthPlaceComponentHook(name, disabled),
            }
        ],
    },
    {
        name: "citizenship",
        label: "citizenship",
        type: "custom",
        x: "1",
        y: "5",
        layout: "col-md-6",
        component: (name, disabled) => citizenshipComponentHook(name, disabled),
    },
    {
        name: "profession",
        label: "profession",
        type: "text",
        x: "2",
        y: "5",
        layout: "col-md-6",
    },
    {
        name: "addressBox",
        label: "Address",
        type: "box",
        x: "1",
        y: "6",
        layout: "col-md-12",
        components: [
            {
                name: "address",
                layout: "col-md-12",
                type: "custom",
                x: "1",
                y: "1",
                component: (name, disabled) => addressComponentHook(name, disabled),
            }
        ],
    },
    {
        name: "chargedWith",
        label: "chargedWith",
        type: "text",
        x: "1",
        y: "7",
        layout: "col-md-6",
    },
    {
        name: "articles",
        label: "articles",
        type: "text",
        x: "1",
        y: "9",
        layout: "col-md-6",
    },
    {
        name: "flagrantDelits",
        label: "flagrantDelits",
        type: "date",
        x: "1",
        y: "8",
        layout: "col-md-6",
    },
    {
        name: "citationDirect",
        label: "citationDirect",
        type: "date",
        x: "2",
        y: "8",
        layout: "col-md-6",
    },
    {
        name: "codePenal",
        label: "codePenal",
        type: "text",
        x: "2",
        y: "9",
        layout: "col-md-6",
    }
]


export const form2dto = (formData, dto) => {
    if (formData.id != null) {
        dto.id = parseInt(formData.id)
    }
   
    customMandatDepotForm2Dto(formData, dto);
}

export const dto2form = (dto) => {
    let form = dto;

    customMandatDepotDto2Form(dto, form);
    return form;
}

const getButtons = (id, onFinish) => {
    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    
                        <button style={{ minWidth: '5rem' }} className={'reject-button'} type="submit">
                            {t`Imprimer`}
                        </button>
                    
                </div>
            </div>
        </>
    )
}

class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
	    let MandatDepotForm = createFormComponent(mandatDepotFields);
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={() => this.handleDontSave(this.props.id)}
			/>
			<MandatDepotForm ref={this.myRef} key={this.props.key} loadData={this.props.loadData} onSubmit={this.props.onSubmit} id={this.props.id} buttons={() => getButtons(this.props.id, this.props.onFinish)} />
		</>
		)
	}
}

export const displayNewMandatDepotForm = (gui, formData) => {
	const readNewMandatDepotForm = (onFinish) => (id) => {
        let uuid = uuidv4();
        return {
            //uuid, view: () => <FormComponent key={uuid} loadData={async () => buildEmptyObject(mandatDepotFields)} onSubmit={(onFinish)?(data) => save(data).then(() => onFinish()):save}/>
            uuid, view: () => <FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>

        };
    }
    let data;
    if (!formData) {
        data = buildEmptyObject(mandatDepotFields);
        data.draft = true;
        save(data).then((response) => {
            gui.goTo(readNewMandatDepotForm(() => printMandatDepot(response)), response)
        });
    } else {
        data = _.clone(formData);
        data.draft = true;
        form2dto(formData, data);
        if (formData.image) {
            if (!formData.image.isEmpty) {
                let base64Image = formData.image.url;
                fetch(base64Image)
                .then(res => res.blob())
                .then(blob => {
                    let faceMimeType = "image/png";
                    data.face = blob;
                    data.faceMimeType = faceMimeType;
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        data.image.url = reader.result;
                        save(data).then((response) => {
                            gui.goTo(readNewMandatDepotForm(() => printMandatDepot(response)), response)
                        });
                    };
                    reader.readAsDataURL(blob);
                });
            } else {
                save(data).then((response) => {
                    gui.goTo(readNewMandatDepotForm(() => printMandatDepot(response)), response)
                });
            }
        } else {
            save(data).then((response) => {
                gui.goTo(readNewMandatDepotForm(() => printMandatDepot(response)), response)
            });
        }
    }
}

export const displayReadMandatDepotForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>
	};
}

const buildEmptyObject = (fields) => {
	const empty = {};
	for (let i = 0; i < fields.length; i++) {
		let field = fields[i];
		switch (field.type) {
			case ("text"):
				empty[field.name] = "";
				break;
			case ("number"):
				empty[field.name] = "";
				break;
			case ("checkbox"):
				empty[field.name] = false;
				break;
			case ("timestampz"):
				empty[field.name] = '';
				break;
			case ("date"):
				empty[field.name] = null;
				break;
			case ("select"): // dynamic lists, loaded from the backend
				empty[field.name] = '';
				break;
			case ("list"): // static lists, hardcoded
				empty[field.name] = '';
				break;
			case ("password"):
				empty[field.name] = '';
				break;
            case ("image"):
                empty[field.name] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: '/public/avatar.png', isEmpty: true};
                break;
		}
	}
	return empty;
}

export const loadFormData = async (id) => {
	return await rest.read('mandat-depot', id, getDjServicesUrl()).then(response => {
		let form = dto2form(response);
		return form;
	})
}

const save = async (formData) => {
	let data = _.clone(formData);
	let dto = data;
	form2dto(formData, dto);
	try {
        return rest.request(getDjServicesUrl() + 'mandat-depot/new-document', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
				return response;
		});
    } catch (err) {
        alert(err);
    }
}

const update = async (formData) => {
	let data = _.clone(formData);
	let dto = data;
	form2dto(formData, dto)
	try {
        return rest.request(getDjServicesUrl() + 'mandat-depot/edit-document', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
		});
    } catch (err) {
        alert(err);
    }
}