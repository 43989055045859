import React, { useState } from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { getDjServicesUrl, getServiceUri, pojoMetadata } from "../../../../auto/js/metadata";

import { rest, t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent"
import { Checkbox, FormControlLabel } from "@mui/material";
import Facets from "../../widgets/Facets";
import {
    getOrderBy,
    getErrorList,
    transformAdvancedSearchData,
    advancedSearchfields,
    getRowActions
} from "../../../../main/js/lists/mandatDepot/MandatDepotListCommon.jsx";


const mandatDepotListFields = [
    {
        title: "Numéro de série",
        field: "id",
    },
    {
        title: "firstname",
        field: "firstname",
    },
    {
        title: "lastname",
        field: "lastname",
    },
    {
        title: "birthdate",
        field: "birthdate",
    },
    {
        title: "Actions",
        field: "actions",
        render: (rowData) => getRowActions(rowData)
    }
]

const MandatDepotListPage = ({uuid, gui, searchData}) => {
    const [errorList, setErrorList] = useState([]);
    const [selectAllStatus, setSelectAllStatus] = useState(false);
    const [selectAllError, setSelectAllError] = useState(false);
    const [advancedSearchData, setAdvancedSearchData] = useState(searchData);
    let MandatDepotTable = createTableComponent(mandatDepotListFields);

    const onFacetChange = (key, value) => {
        switch(key) {
            case ("selectAllStatus"):
            	setSelectAllStatus(value.target.checked);
				break;
        }
    }
    const buildData = async (query) => {
        let filter = query;
        let data;
        filter["mandat-depot-document"] = {}
        filter['and'] = true;
        filter["orderBy"] = getOrderBy();
        if (advancedSearchData)
            filter["query"] = advancedSearchData;
        filter.orderDirection = null;
        filter.offset = query.page * query.pageSize;
        if (query.search && query.search!='') {
            pojoMetadata["mandat-depot-document"].columns.forEach(element => {
                if(element.type=='text' ){
                    filter["mandat-depot-document"][element.key]= query.search;
                }
            });
            filter['and'] = false;
            filter.fuzziness = "AUTO";
        }
        return await getData(filter).then(response => {
            data = filterData(response, gui);
            return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
        });
    }

    const onErrorFacetChange = (key, value) => {
        let list = _.clone(errorList);
        if (key === "selectAllError") {
            if (value.target.checked)
                list = selectAllErrors();
            else
                list = [];
            setErrorList(list);
            setSelectAllError(value.target.checked)
        }
        if (value.target.checked && !list.includes(key)) {
            list.push(key);
            setErrorList(list);
        }
        else if (!value.target.checked && list.includes(key)) {
            let index = list.indexOf(key);
            list.splice(index, 1);
            setErrorList(list);
        }
    }

    const getErrorCodeFacets = () => {
      let list = [{key: "selectAllError", value: selectAllError, label: t`select/deselect all`, separator: true}];
        getErrorList().forEach(error => {
            list.push({key: error, value: errorList.includes(error), label: t(error)});

        });
        return list;
    }

    const onAdvancedSearchSubmit = (data) => {
        let dto = transformAdvancedSearchData(data);
        setAdvancedSearchData(dto);
    }

    const buildFacets = (key, value, label, handleChange) => {
        return (
            <>
            <FormControlLabel
            control={<Checkbox
              checked={value}
              onChange={(event) => handleChange(key, event)}
              color="primary"
              value={value}
            />
            }
            label={label}
            style={{width: '100%'}}
            />
            </>
        )
    }

    return (
        <div>

            <MandatDepotTable key={uuid} loadData={async (query) => buildData(query)} advancedSearchFields={advancedSearchfields} onSubmit={onAdvancedSearchSubmit}/>
    	</div>
    )
}

export const displayMandatDepotList = (gui) => {
	const readMandatDepotList = () => {
    let uuid = uuidv4();
		return {
			uuid, view: () => <MandatDepotListPage gui={gui} key={uuid} />
		};
	}
	gui.goTo(readMandatDepotList)
}

const selectAllErrors = () => {
    let list = ["selectAllError"];
    getErrorList().forEach(error => {
            list.push(error);
    });
    return list;
}

/* const getTableActions = (gui) => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
            tooltip: t`Edit`,
            onClick: (event, rowData) => {
            }
        }
    )
    return actions;
} */

export const getData = async (filter) => {
    return await rest.request(getDjServicesUrl() + "mandat-depot/search-document/", "POST", filter);
}

export const countData = async (filter) => {
    return await rest.request(getDjServicesUrl() + "mandat-depot/count-document/", "POST", filter);
}

export const filterData = (defaultRows, gui) => {
    const newRows = [];
    defaultRows.forEach((row) => {
        let date = row.birthdate;
        if (date != null) {
            row.birthdate = date[2] + "-" + date[1] + "-" + date[0];
        }
        row.gui = gui;
        newRows.push(row);
    });
    return newRows;
}