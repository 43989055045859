import { printTemplate } from "../../utils";
import { rest } from "../../../../auto/js/services";
import { setContextualOptions } from "../../../../auto/js/widgets";
import { getDjServicesUrl, getServiceUri } from "../../../../auto/js/metadata";
import { displayNewMandatAmenerForm } from "../../../../auto/js/forms/mandatAmener/NewMandatAmenerForm";
import { whoami } from "../../../../auto/js/users/UserInfo";

export const customAudienceForm2Dto = (formData, dto) => {
    if (formData.victimes !== null && formData.victimes) {
		let victimes = Object.keys(formData.victimes).join(',');
		dto.victimes = victimes;
	} else
        dto.victimes = null;
    if (formData.plaignants !== null && formData.plaignants) {
		let plaignants = Object.keys(formData.plaignants).join(',');
		dto.plaignants = plaignants;
	} else
        dto.plaignants = null;
    if (formData.misCauses !== null && formData.misCauses) {
		let misCauses = Object.keys(formData.misCauses).join(',');
		dto.misCauses = misCauses;
	}
    else
        dto.misCauses = null;
    if (formData.avocats !== null && formData.avocats) {
		let avocats = formData.avocats.join(',');
		dto.avocats = avocats;
	}
}



export const getAvocatsOptions = async () => {
    return rest.request(getServiceUri() + 'barreau/get-avocats', 'GET').then(response => {
        const result = response.reduce((acc, item) => {
            acc[item.id] = `${item.name}`;
            return acc;
          }, {});
          return result
    })
}

export const customAudienceDto2form = (dto, form) => {
    rest.read('forms/citation-cour-d-appel', form["id"], getDjServicesUrl()).then(async citationCourDAppelData => {
        rest.read('forms/convocation', form["id"], getDjServicesUrl()).then(async convocationData => {
            rest.read('forms/extrait-plumitif', form["id"], getDjServicesUrl()).then(async plumitifData => {
                setContextualOptions({
                    "dossier": {
                        submenu: {
                            "actions": {
                                label: "Actions",
                                options: {
                                    "print-citation-cour-d-appel": {
                                        label: "Imprimer citation cour d'appel",
                                        do: printTemplate(citationCourDAppelData)
                                    },
                                    "print-convocation": {
                                        label: "Imprimer convocation",
                                        do: printTemplate(convocationData)
                                    },
                                    "print-extrait-plumitif": {
                                        label: "Imprimer extrait de plumitif",
                                        do: printTemplate(plumitifData)
                                    },
                                    "nouveau-ma": {
                                        label: "Nouveau mandat d'amener",
                                        do: (gui) => {
                                            let mdFormData = {audienceDate: form.date, audienceHour: form.time}
                                            mdFormData.president = whoami().lastname + ' ' + whoami().firstname;
                                            let personnes = '';
                                            if (form.misCauses) {
                                                Object.values(form.misCauses).forEach(element => {
                                                    personnes = personnes + ' ' + element;
                                                });
                                                mdFormData.personne = personnes;
                                            } 
                                            displayNewMandatAmenerForm(gui, mdFormData)
                                        }
                                    }
                                }
                            }
                        }
                    }
                })
            })
        })
    })
    if (dto.avocats == null || dto.avocats === "")
        dto.avocats = []
    else {
        let values = [];
        let components = dto.avocats.split(",");
        components.forEach(element => {
            values.push(parseInt(element))
        });
        dto.avocats = values;
    }
   /*  if (dto.plaignants == null || dto.plaignants === "")
        dto.plaignants = []
    else {
        let values = [];
        let components = dto.plaignants.split(",");
        components.forEach(element => {
            values.push(parseInt(element))
        });
        dto.plaignants = values;
    }
    if (dto.victimes == null || dto.victimes === "")
        dto.victimes = []
    else {
        let values = [];
        let components = dto.victimes.split(",");
        components.forEach(element => {
            values.push(parseInt(element))
        });
        dto.victimes = values;
    }
    if (dto.avocats == null || dto.avocats === "")
        dto.avocats = []
    else {
        let values = [];
        let components = dto.avocats.split(",");
        components.forEach(element => {
            values.push(parseInt(element))
        });
        dto.avocats = values;
    }
    if (dto.misCauses == null || dto.misCauses === "")
        dto.misCauses = []
    else {
        let values = [];
        let components = dto.misCauses.split(",");
        components.forEach(element => {
            values.push(parseInt(element))
        });
        dto.misCauses = values;
    } */
}