import React, { useState } from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { getDjServicesUrl, getServiceUri, pojoMetadata } from "../../../../auto/js/metadata";

import { rest, t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent"
import { Checkbox, FormControlLabel } from "@mui/material";
import Facets from "../../widgets/Facets";
import {
    getOrderBy,
    getErrorList,
    transformAdvancedSearchData,
    advancedSearchfields,
    getRowActions
} from "../../../../main/js/lists/registreOpposition/RegistreOppositionListCommon.jsx";


const registreOppositionListFields = [
    {
        title: "Numéro électronique dans le registre d'opposition",
        field: "id",
    },
    {
        title: "Numéro électronique du RP",
        field: "rpId",
    },
    { title: "Plaignants", field: "plaignants" },
    { title: "Mis en Causes", field: "misCause" },
    { title: "Victimes", field: "victimes" },
    {
        title: "Judgment Number",
        field: "judgmentNumber",
    },
    {
        title: "Judgment Date",
        field: "judgmentDate",
    },
    {
        title: "Actions",
        field: "actions",
        render: (rowData) => getRowActions(rowData)
    }
]

const RegistreOppositionListPage = ({uuid, gui, searchData}) => {
    const [errorList, setErrorList] = useState([]);
    const [selectAllStatus, setSelectAllStatus] = useState(false);
    const [selectAllError, setSelectAllError] = useState(false);
    const [advancedSearchData, setAdvancedSearchData] = useState(searchData);
    let RegistreOppositionTable = createTableComponent(registreOppositionListFields);

    const onFacetChange = (key, value) => {
        switch(key) {
            case ("selectAllStatus"):
            	setSelectAllStatus(value.target.checked);
				break;
        }
    }
    const buildData = async (query) => {
        let filter = query;
        let data;
        filter["registre-opposition-document"] = {}
        filter['and'] = true;
        filter["orderBy"] = getOrderBy();
        if (advancedSearchData)
            filter["query"] = advancedSearchData;
        filter.orderDirection = null;
        filter.offset = query.page * query.pageSize;
        if (query.search && query.search!='') {
            pojoMetadata["registre-opposition"].columns.forEach(element => {
                if(element.type=='text' ){
                    filter["registre-opposition-document"][element.key]= query.search;
                }
            });
            filter['and'] = false;
            filter.fuzziness = "AUTO";
        }
        return await getData(filter).then(response => {
            return filterData(response, gui).then(data => {
                return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
            })
        });
    }

    const onErrorFacetChange = (key, value) => {
        let list = _.clone(errorList);
        if (key === "selectAllError") {
            if (value.target.checked)
                list = selectAllErrors();
            else
                list = [];
            setErrorList(list);
            setSelectAllError(value.target.checked)
        }
        if (value.target.checked && !list.includes(key)) {
            list.push(key);
            setErrorList(list);
        }
        else if (!value.target.checked && list.includes(key)) {
            let index = list.indexOf(key);
            list.splice(index, 1);
            setErrorList(list);
        }
    }

    const getErrorCodeFacets = () => {
      let list = [{key: "selectAllError", value: selectAllError, label: t`select/deselect all`, separator: true}];
        getErrorList().forEach(error => {
            list.push({key: error, value: errorList.includes(error), label: t(error)});

        });
        return list;
    }

    const onAdvancedSearchSubmit = (data) => {
        let dto = transformAdvancedSearchData(data);
        setAdvancedSearchData(dto);
    }

    const buildFacets = (key, value, label, handleChange) => {
        return (
            <>
            <FormControlLabel
            control={<Checkbox
              checked={value}
              onChange={(event) => handleChange(key, event)}
              color="primary"
              value={value}
            />
            }
            label={label}
            style={{width: '100%'}}
            />
            </>
        )
    }

    return (
        <div>

          <RegistreOppositionTable key={uuid} loadData={async (query) => buildData(query)} advancedSearchFields={advancedSearchfields} onSubmit={onAdvancedSearchSubmit}/>
    	</div>
    )
}

export const displayRegistreOppositionList = (gui) => {
	const readRegistreOppositionList = () => {
    let uuid = uuidv4();
		return {
			uuid, view: () => <RegistreOppositionListPage gui={gui} key={uuid} />
		};
	}
	gui.goTo(readRegistreOppositionList)
}

const selectAllErrors = () => {
    let list = ["selectAllError"];
    getErrorList().forEach(error => {
            list.push(error);
    });
    return list;
}

/* const getTableActions = (gui) => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
            tooltip: t`Edit`,
            onClick: (event, rowData) => {
            }
        }
    )
    return actions;
} */

export const getData = async (filter) => {
    return await rest.request(getServiceUri() + "registre-opposition/search-document/", "POST", filter);
}

export const countData = async (filter) => {
    return await rest.request(getServiceUri() + "registre-opposition/count-document/", "POST", filter);
}

export const filterData = async (defaultRows, gui) => {
    const newRows = [];
    for (let i in defaultRows) {
        let row = defaultRows[i];
        let date = row.judgmentDate;
        if (date != null) {
            row.judgmentDate = date[2] + "-" + date[1] + "-" + date[0];
        }
        row.gui = gui;
        let plaignants = '';
        let victimes = '';
        let misCauses = '';
        let personFilter = {and: true};
        personFilter['folderId'] = row.folderId;
        let pFirst = true;
        let vFirst = true;
        let mFirst = true;
        await rest.search('parties', personFilter, getDjServicesUrl()).then(response => {
            Object.keys(response).forEach((element) => {
                if (element === "1") {
                    let p = response[element];
                    if (p.length) {
                        p.forEach(plaignant => {
                            if (pFirst) {
                                plaignants = plaignant.firstname;
                                pFirst = false;
                            } 
                            else {
                                plaignants = plaignants.concat(', ', plaignant.firstname)
                            }
                            if (plaignant.lastname != null) 
                                plaignants = plaignants.concat(' ', plaignant.lastname)
                        })
                        
                    }
                    
                }
                if (element === "2") {
                    let v = response[element];
                    if (v.length) {
                        v.forEach(victime => {
                            if (vFirst) {
                                victimes = victime.firstname;
                                vFirst = false;
                            } 
                            else {
                                victimes = victimes.concat(', ', victime.firstname)
                            }
                            if (victime.lastname != null) 
                                victimes = victimes.concat(' ', victime.lastname)
                        })
                    }
                    
                }
                if (element === "3") {
                    let m = response[element];
                    if (m.length) {
                        m.forEach(misCause => {
                            if (mFirst) {
                                misCauses = misCause.firstname;
                                mFirst = false;
                            } 
                            else {
                                misCauses = misCauses.concat(', ', misCause.firstname)
                            }
                            if (misCause.lastname != null) 
                                misCauses = misCauses.concat(' ', misCause.lastname)
                        })
                    }
                   
                }
            })
            
            
        })
        row.plaignants = plaignants;
        row.victimes = victimes;
        row.misCause = misCauses;
        newRows.push(row);
    };
    return newRows;
}